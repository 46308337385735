const filterReducers = {
  filterSchool: (state, action) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        school: action.payload._id,
        teacher: action.payload.teachers.length
          ? action.payload.teachers[0]._id
          : { _id: "" },
      },
    }
  },
  filterTeacher: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, teacher: action.payload },
    }
  },
  filterDay: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, date: action.payload },
    }
  },
  filterTodoCategory: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, todoCategory: action.payload },
    }
  },
  filterWaitingLists: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, waitingList: action.payload },
    }
  },
  filterMailSchool: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, mailSchool: action.payload },
    }
  },
  filterMailTeacher: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, mailTeacher: action.payload },
    }
  },
  filterMailDay: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, mailDay: action.payload },
    }
  },
  filterMailDate: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, mailDate: action.payload },
    }
  },
  filterMailAllStudents: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, mailAllStudents: action.payload },
    }
  },
  filterHoursTeacher: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, hoursTeacher: action.payload },
    }
  },
  filterStudentsTeacher: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, studentsTeacher: action.payload },
    }
  },
  filterNoInvoiceSchool: (state, action) => {
    return {
      ...state,
      filters: { ...state.filters, noInvoiceSchool: action.payload },
    }
  },
}

export default filterReducers
