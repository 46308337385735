import React from "react"
import { IconButton, makeStyles, Tooltip } from "@material-ui/core"
import {
  PersonAddOutlined,
  SchoolOutlined,
  AccountBalanceOutlined,
  DateRangeOutlined,
  DescriptionOutlined,
  SwapHoriz,
} from "@material-ui/icons"
import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1),
    border: `solid 1px ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    background: "white",
  },
  one: {
    background: "purple",
  },
  two: {
    background: "blue",
  },
}))

const AddItems = () => {
  const classes = useStyles()
  return (
    <div>
      <Link to="/addyearschedule">
        <Tooltip title="Jaarrooster aanpassen" placement="left">
          <IconButton className={classes.root}>
            <DateRangeOutlined />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/changeteacher">
        <Tooltip title="Docent wisselen" placement="left">
          <IconButton className={classes.root}>
            <SwapHoriz />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/makecontract">
        <Tooltip title="Contract maken" placement="left">
          <IconButton className={classes.root}>
            <DescriptionOutlined />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/addschool">
        <Tooltip title="Nieuwe school toevoegen" placement="left">
          <IconButton className={classes.root}>
            <AccountBalanceOutlined />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/addteacher">
        <Tooltip title="Nieuwe docent toevoegen" placement="left">
          <IconButton className={classes.root}>
            <SchoolOutlined />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/addstudent">
        <Tooltip title="Nieuwe leerling toevoegen" placement="left">
          <IconButton className={classes.root}>
            <PersonAddOutlined />
          </IconButton>
        </Tooltip>
      </Link>
    </div>
  )
}

export default AddItems
