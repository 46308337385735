const loaderReducers = {
  setLoader: (state, action) => {
    return {
      ...state,
      loaders: {
        ...state.loaders,
        [action.payload]: !state.loaders[action.payload],
      },
    }
  },
}

export default loaderReducers
