import React, { useState } from "react"
import Dialog from "../common/Dialog"
import useUnsubscribes from "../hooks/useUnsubscribes"
import { differenceInCalendarDays, parseISO, format } from "date-fns"
import { useHistory } from "react-router-dom"
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core"
import { nl } from "date-fns/locale"
import { Alert } from "@material-ui/lab"
import { TramOutlined } from "@material-ui/icons"

const UnsubscribeWarning = () => {
  const history = useHistory()
  const unsubscribes = useUnsubscribes()
  const [open, setOpen] = useState(TramOutlined)
  let expired = unsubscribes.filter(
    ({ date }) => differenceInCalendarDays(parseISO(date), new Date()) < 14
  )

  const handleClose = () => setOpen(false)

  const handleSubmit = () => {
    history.push("/unsubscribes")
    handleClose()
  }

  return (
    <Dialog
      loading={false}
      maxWidth="sm"
      title="Onverwerkte uitschrijvingen"
      open={Boolean(expired.length && open)}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      confirmBtn="Naar uitschrijvingen"
    >
      <Alert severity="warning">
        Onderstaande leerlingen zijn uitgeschreven maar nog niet verwerkt!
      </Alert>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Naam leerling</TableCell>
            <TableCell align="right">Uitschrijfdatum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {expired.map((i) => (
            <TableRow key={i._id}>
              <TableCell>{i.name}</TableCell>
              <TableCell align="right">
                {format(parseISO(i.date), "eeee dd MMMM yyyy", { locale: nl })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  )
}

export default UnsubscribeWarning
