import axios from "axios"
import { notify } from "../common/Notification"

// send cookies along with every request
axios.defaults.withCredentials = true

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500

  if (!expectedError) {
    notify("Server Fout", "error")
    console.log(error)
  }

  return Promise.reject(error)
})

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
}

export default http
