import { LessonContext } from "../context/LessonContext"
import { useContext } from "react"

const useGroupLessons = () => {
  const { lessonState } = useContext(LessonContext)
  let { regularLessons, lessonCards, testLessons } = lessonState

  regularLessons = regularLessons.filter(lesson => lesson.isGroup === true)
  lessonCards = lessonCards.filter(lesson => lesson.isGroup === true)
  testLessons = testLessons.filter(lesson => lesson.isGroup === true)

  return [...regularLessons, ...lessonCards, ...testLessons]
}

export default useGroupLessons
