import React, { useState } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { makeStyles } from "@material-ui/core/styles"

export let notify

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}))

export default function Notification() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [color, setColor] = useState("info")
  const [duration, setDuration] = useState(6000)

  const openNotification = (message, color, duration) => {
    setOpen(true)
    setMessage(message)
    setColor(color || "info")
    setDuration(duration || 6000)
  }

  notify = openNotification

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={duration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} color={color}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}
