import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Input from "../common/Input"
import LoadingButton from "../common/LoadingButton"
import styles from "../themesAndStyles/styles"
import gfLogo from "../img/gfLogo.png"
import http from "../services/http"
import validateResetPassword from "../validation/validateResetPassword"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  loginwrapper: {
    margin: "auto",
    backgroundColor: "white",
    boxShadow: theme.shadows[10],
    width: "33%",
    minWidth: 270,
    maxWidth: 512,
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 4,
  },
  btn: {
    marginTop: theme.spacing(1),
  },
  link: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
  },
  img: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(-3) - 40.5,
    marginBottom: theme.spacing(3),
  },
}))

const ForgotPassword = (props) => {
  const token = props.match.params.token
  const [state, setState] = useState({ password: "" })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const handleSubmit = async () => {
    const errors = validateResetPassword(state)
    if (errors) return setErrors(errors)
    setErrors({})
    try {
      setLoading(true)
      await http.post(
        `${process.env.REACT_APP_API}/user/resetpw/${token}`,
        state
      )
      setLoading(false)
      props.history.push("/")
    } catch (error) {
      console.log(error.response.status)
      if (error.response.status === 400 || error.response.status === 401)
        setErrors({ ...errors, ...error.response.data })
      setLoading(false)
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.loginwrapper}>
        <div className={classes.img}>
          <img src={gfLogo} alt="Logo Guitar Factory" />
        </div>
        {errors.error ? (
          <div className="errorpaper">{errors.error || errors}</div>
        ) : (
          <div className="infopaper">
            Vul een nieuw wachtwoord in en klik op verzenden. Hierna is jouw
            wachtwoord gewijzigd en kun je opnieuw proberen in te loggen.
          </div>
        )}
        <Input
          type="password"
          label="Nieuw Wachtwoord"
          error={errors.password}
          value={state.password}
          onChange={(e) => setState({ ...state, password: e.target.value })}
        />
        <LoadingButton
          onClick={handleSubmit}
          type="submit"
          className={classes.btn}
          loading={loading}
          label="Verzenden"
          variant={styles.mainBtn}
          color={styles.mainBtnColor}
        />
      </div>
    </div>
  )
}

export default ForgotPassword
