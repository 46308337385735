import { useContext } from "react"
import { AppContext } from "../context/AppContext"

const useTodos = () => {
  const { state } = useContext(AppContext)
  const { tasks, categorys } = state.todo
  return { tasks, categorys }
}

export default useTodos
