import validate from "./validate"
import Joi from "joi-browser"

const schema = {
  username: Joi.string()
    .email()
    .required()
    .error(() => {
      return {
        message: "Gebruikersnaam is verplicht moet een e-mailadres zijn",
      }
    }),
  password: Joi.string()
    .required()
    .error(() => {
      return {
        message: "Wachtwoord is verplicht",
      }
    }),
}

const validateLogin = (input) => validate(input, schema)

export default validateLogin
