import { useContext } from "react"
import { TeacherContext } from "../context/TeacherContext"
import _ from "lodash"

const useTeachers = () => {
  const { teacherState } = useContext(TeacherContext)
  const { teachers } = teacherState

  return _.sortBy(teachers, ["firstName", "lastName"])
}

export default useTeachers
