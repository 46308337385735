import React, { useReducer } from "react"

const reducer = (state, action) => {
  switch (action.type) {
    case "setteachers":
      return { ...state, teachers: action.payload }
    case "addteacher":
      return { ...state, teachers: [...state.teachers, action.payload] }
    case "updateteacher":
      const filteredTeachers = state.teachers.filter(
        teacher => teacher._id !== action.payload._id
      )
      return { ...state, teachers: [...filteredTeachers, action.payload] }
    case "deleteteacher":
      const teachers = state.teachers.filter(
        teacher => teacher._id !== action.payload
      )
      return { ...state, teachers }
    default:
      return { ...state }
  }
}

const initialState = { teachers: [{ _id: "foo", schools: [] }] }

const TeacherContext = React.createContext(initialState)

const TeacherContextProvider = props => {
  const [teacherState, teacherDispatch] = useReducer(reducer, initialState)
  return (
    <TeacherContext.Provider value={{ teacherState, teacherDispatch }}>
      {props.children}
    </TeacherContext.Provider>
  )
}

export { TeacherContext, TeacherContextProvider }
