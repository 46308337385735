import { makeStyles } from "@material-ui/core"
import colors from "./colors"

const useStyles = makeStyles(theme => ({
  "@global": {
    ".emptyIconBtn": {
      "&:hover": {
        background: "inherit",
        cursor: "default"
      }
    },
    ".paper": {
      padding: theme.spacing(2),
      border: `solid 1px #cfd8dc`,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      boxShadow: theme.shadows[0]
    },
    ".errorpaper": {
      boxShadow: theme.shadows[0],
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      background: colors.errorbg,
      color: theme.palette.error.main
    },
    ".infopaper": {
      boxShadow: theme.shadows[0],
      border: `1px solid lightgrey`,
      borderRadius: 4,
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      background: colors.infobg,
      color: "grey"
    },
    ".formwrapper": {
      border: `1px solid lightgrey`,
      borderRadius: 4,
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      background: colors.wrapperbg,
      boxShadow: theme.shadows[0]
    },
    ".optionwrapper": {
      background: colors.option
    },
    ".testlessonwrapper": {
      background: colors.testlesson
    },
    ".regularlessonwrapper": {
      background: colors.regularlesson
    },
    ".lessoncardwrapper": {
      background: colors.lessoncard
    },
    ".lessoninfowrapper": {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderBottom: "solid 1px lightgrey"
    },
    ".link": {
      color: "#006064",
      textDecoration: "none",
      fontWeight: "bold"
    }
  }
}))

export default useStyles
