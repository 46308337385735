import React, { useReducer } from "react"

const emptyStudent = {
  _id: "",
  email: [],
  regularLessons: [],
  lessonCards: [],
  testLessons: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setstudents":
      return { ...state, students: action.payload }
    case "updatestudent":
      const students = state.students.filter(
        student => student._id !== action.payload._id
      )
      return { ...state, students: [...students, action.payload] }
    default:
      return { ...state }
  }
}

const initialState = { students: [emptyStudent] }

const StudentContext = React.createContext(initialState)

const StudentContextProvider = props => {
  const [studentState, studentDispatch] = useReducer(reducer, initialState)
  return (
    <StudentContext.Provider value={{ studentState, studentDispatch }}>
      {props.children}
    </StudentContext.Provider>
  )
}

export { StudentContext, StudentContextProvider }
