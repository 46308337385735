import React, { useContext, useState } from "react"
import {
  AppBar,
  Toolbar,
  Badge,
  Tooltip,
  Menu,
  MenuItem,
  Hidden,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import {
  PaymentOutlined,
  SettingsOutlined,
  EmojiPeopleTwoTone,
  SchoolOutlined,
  SettingsEthernet,
  AttachMoneyOutlined,
} from "@material-ui/icons"
import { StudentContext } from "../context/StudentContext"
import auth from "../services/auth"
import FullPageSpinner from "../common/FullPageSpinner"
import ManagerDesktop from "./ManagerDesktop"
import ManagerMobile from "./ManagerMobile"
import useUnsubscribes from "../hooks/useUnsubscribes"
import { useOpenTeacherContracts } from "../hooks/useOpenTeacherContracts"
import { useNoMoneybirdStudents } from "../hooks/useNoMoneyBirdStudents"
import { useNoBtwPlichtigStudents } from "../hooks/useNoBtwPlichtigStudents"

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  links: { flexGrow: 1 },
  navLink: {
    borderRadius: 4,
    color: "white",
    textDecoration: "none",
    fontWeight: "bolder",
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    boxSizing: "border-box",
  },
  menuLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    textAlign: "right",
  },
}))

const ManagerNavBar = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [loggingOut, setLoggingOut] = useState(false)
  const { studentState } = useContext(StudentContext)
  const unsubscribes = useUnsubscribes()
  const teacherContracts = useOpenTeacherContracts()
  const noMbStudents = useNoMoneybirdStudents()
  const noBtwStudents = useNoBtwPlichtigStudents()

  const noInvoice = studentState.students.filter(
    (student) => student.inAdmin === false
  )

  const handleSettingsClose = () => setAnchorEl(null)

  return (
    <div className={classes.root}>
      {loggingOut && <FullPageSpinner label="Uitloggen..." />}
      <AppBar position="static">
        <Toolbar>
          <div className={classes.links}>
            <Hidden mdDown>
              <ManagerDesktop />
            </Hidden>
            <Hidden lgUp>
              <ManagerMobile />
            </Hidden>
          </div>
          <div>
            {!!noBtwStudents.length && (
              <Hidden xsDown>
                <Tooltip title="Btwstatus onbekend" placement="left">
                  <NavLink to="/no-btw" className={classes.navLink}>
                    <Badge
                      color="secondary"
                      badgeContent={noBtwStudents.length}
                      max={999}
                    >
                      <AttachMoneyOutlined />
                    </Badge>
                  </NavLink>
                </Tooltip>
              </Hidden>
            )}
            {!!noMbStudents.length && (
              <Hidden xsDown>
                <Tooltip title="Moneybird Koppeling" placement="left">
                  <NavLink to="/no-moneybird" className={classes.navLink}>
                    <Badge
                      color="secondary"
                      badgeContent={noMbStudents.length}
                      max={999}
                    >
                      <SettingsEthernet />
                    </Badge>
                  </NavLink>
                </Tooltip>
              </Hidden>
            )}
            {!!unsubscribes.length && (
              <Hidden xsDown>
                <Tooltip title="Uitschrijvingen" placement="left">
                  <NavLink to="/unsubscribes" className={classes.navLink}>
                    <Badge color="error" badgeContent={unsubscribes.length}>
                      <EmojiPeopleTwoTone />
                    </Badge>
                  </NavLink>
                </Tooltip>
              </Hidden>
            )}
            {!!teacherContracts.length && (
              <Hidden xsDown>
                <Tooltip title="Contracten docenten" placement="left">
                  <NavLink to="/teachersandschools" className={classes.navLink}>
                    <Badge color="error" badgeContent={teacherContracts.length}>
                      <SchoolOutlined />
                    </Badge>
                  </NavLink>
                </Tooltip>
              </Hidden>
            )}
            {!!noInvoice.length && (
              <Hidden xsDown>
                <Tooltip title="Te Factureren" placement="left">
                  <NavLink to="/noinvoice" className={classes.navLink}>
                    <Badge color="error" badgeContent={noInvoice.length}>
                      <PaymentOutlined />
                    </Badge>
                  </NavLink>
                </Tooltip>
              </Hidden>
            )}
            <Tooltip title="Settings" placement="left">
              <Badge>
                <SettingsOutlined
                  aria-controls="settingsMenu"
                  aria-haspopup="true"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                />
              </Badge>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        id="settingsMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleSettingsClose}
      >
        <NavLink className={classes.menuLink} to="/accountsettings">
          <MenuItem onClick={handleSettingsClose}>Mijn Account</MenuItem>
        </NavLink>
        <NavLink className={classes.menuLink} to="/manageusers">
          <MenuItem onClick={handleSettingsClose}>Accounts beheren</MenuItem>
        </NavLink>
        <NavLink className={classes.menuLink} to="/teachersandschools">
          <MenuItem onClick={handleSettingsClose}>Scholen en Docenten</MenuItem>
        </NavLink>
        <NavLink className={classes.menuLink} to="/prices">
          <MenuItem onClick={handleSettingsClose}>Tarieven</MenuItem>
        </NavLink>
        <MenuItem
          className={classes.menuLink}
          onClick={() => {
            setLoggingOut(true)
            auth.logout()
          }}
        >
          Uitloggen
        </MenuItem>
      </Menu>
    </div>
  )
}

export default ManagerNavBar
