const translateTypeOfLesson = (typeOfLesson) => {
  switch (typeOfLesson) {
    case "weekly":
    case "other-week-a":
    case "other-week-b":
      return "regularLessons"
    case "testlesson":
      return "testLessons"
    case "lessoncard":
      return "lessonCards"
    default:
      throw new Error("updateLesson: typeOfLesson can not be undefined")
  }
}

const lessonReducers = {
  setLessons: (state, action) => {
    return {
      ...state,
      regularLessons: action.payload.regularLessons || [],
      lessonCards: action.payload.lessonCards || [],
      testLessons: action.payload.testLessons || [],
    }
  },

  deleteLessonFromCard: (state, action) => {
    const lessonCard = state.lessonCards.find(
      (card) => card._id === action.payload.cardId
    )
    const lessons = lessonCard.lessons.filter(
      (lesson) => lesson._id !== action.payload.lessonId
    )

    lessonCard.lessons = lessons
    const lessonCards = state.lessonCards.filter(
      (card) => card._id !== action.payload.cardId
    )
    return { ...state, lessonCards: [...lessonCards, lessonCard] }
  },

  addLessonToCard: (state, action) => {
    const lessonCard = state.lessonCards.find(
      (card) => card._id === action.payload.cardId
    )
    lessonCard.lessons.push(action.payload.lesson)
    const lessonCards = state.lessonCards.filter(
      (card) => card._id !== action.payload.cardId
    )
    return { ...state, lessonCards: [...lessonCards, lessonCard] }
  },

  updateLesson: (state, action) => {
    const lessonArray = translateTypeOfLesson(action.payload.typeOfLesson)
    const newLessons = state[lessonArray].filter(
      (lesson) => lesson._id !== action.payload._id
    )
    newLessons.push(action.payload)
    return { ...state, [lessonArray]: newLessons }
  },
}

export default lessonReducers
