import useAppContext from "./useAppContext"

const useUser = () => {
  const { user } = useAppContext().state
  user.isManager = user.role === "manager"

  return user
}

export default useUser
