import { LessonContext } from "../context/LessonContext"
import { useContext } from "react"

const useTestLessons = () => {
  const { testLessons } = useContext(LessonContext).lessonState

  return testLessons
}

export default useTestLessons
