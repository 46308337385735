import useTestLessons from "./useTestLessons"
import { isToday, isBefore, differenceInDays, parseISO } from "date-fns"
import { format } from "date-fns/esm"

const formattedDate = format(new Date(), "yyyy-MM-dd")
const today = parseISO(formattedDate)

const useExpiredTestLessons = () => {
  const testLessons = useTestLessons()
  return testLessons.filter(lesson => {
    const lessonDate = parseISO(lesson.dateLesson)
    return (
      (isToday(lessonDate) || isBefore(lessonDate, today)) &&
      differenceInDays(today, lessonDate) > 14 &&
      lesson.continuedLessons === null
    )
  })
}

export default useExpiredTestLessons
