import Joi from "joi-browser"
import validate from "./validate"

const schema = {
  username: Joi.string()
    .email()
    .required()
    .error(() => {
      return {
        message:
          "Vul geldige gebruikersnaam in. Dit is altijd een e-mailadres.",
      }
    }),
}

const validateForgotPassword = (input) => validate(input, schema)

export default validateForgotPassword
