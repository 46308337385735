import React from "react"
import { CircularProgress, Typography, Backdrop } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}))

const FullPageSpinner = ({ label }) => {
  const classes = useStyles()
  return (
    <Backdrop open={true} className={classes.backdrop}>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <CircularProgress
          thickness={2}
          size={60}
          style={{ margin: "auto", color: "white" }}
        />
        <Typography
          variant="subtitle2"
          style={{ marginTop: 10, color: "white" }}
        >
          {label || "Laden..."}
        </Typography>
      </div>
    </Backdrop>
  )
}

export default FullPageSpinner
