import Joi from "joi-browser"

const validate = (input, schema) => {
  let errors = {}

  const { error } = Joi.validate(input, schema, {
    abortEarly: false
  })

  error
    ? error.details.forEach(e => {
        errors[e.path[0]] = e.message
      })
    : (errors = null)

  return errors
}

export default validate
