import React, { useState, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Input from "../common/Input"
import LoadingButton from "../common/LoadingButton"
import styles from "../themesAndStyles/styles"
import gfLogo from "../img/gfLogo.png"
import http from "../services/http"
import validateForgotPassword from "../validation/validateForgotPassword"

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  loginwrapper: {
    margin: "auto",
    backgroundColor: "white",
    boxShadow: theme.shadows[10],
    width: "33%",
    minWidth: 270,
    maxWidth: 512,
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 4
  },
  btn: {
    marginTop: theme.spacing(1)
  },
  link: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end"
  },
  img: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(-3) - 40.5,
    marginBottom: theme.spacing(3)
  }
}))

const ForgotPassword = () => {
  const [state, setState] = useState({ username: "" })
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const classes = useStyles()

  const handleSubmit = async () => {
    const errors = validateForgotPassword(state)
    if (errors) return setErrors(errors)
    setErrors({})
    try {
      setLoading(true)
      await http.post(`${process.env.REACT_APP_API}/user/forgotpw`, state)
      setLoading(false)
      setSubmitted(true)
    } catch (error) {
      setLoading(false)
      console.log(error)
      if (error.response.status === 401 || error.response.status === 400)
        setErrors({ ...errors, ...error.response.data })
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.loginwrapper}>
        <div className={classes.img}>
          <img src={gfLogo} alt="Logo Guitar Factory" />
        </div>
        {submitted ? (
          <div className="infopaper">
            Een e-mail met een resetlink is verzonden naar jouw e-mailadres.
            Volg de instructies in de mail om jouw wachtwoord te resetten. Je
            kunt dit venster nu sluiten.
          </div>
        ) : (
          <Fragment>
            {errors.error ? (
              <div className="errorpaper">{errors.error}</div>
            ) : (
              <div className="infopaper">
                Vul hieronder jouw e-mailadres in en klik op verzenden. Je
                ontvangt dan een email met een resetlink om jouw wachtwoord
                opnieuw in te stellen.
              </div>
            )}
            <Input
              label="Emailadres"
              error={errors.username}
              value={state.username}
              onChange={e => setState({ ...state, username: e.target.value })}
            />
            <LoadingButton
              onClick={handleSubmit}
              type="submit"
              className={classes.btn}
              loading={loading}
              label="Verzenden"
              variant={styles.mainBtn}
              color={styles.mainBtnColor}
            />
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default ForgotPassword
