import React from "react"
import MuiDialog from "@material-ui/core/Dialog"
import PropTypes from "prop-types"
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core"
import LoadingButton from "./LoadingButton"

const Dialog = (props) => {
  return (
    <MuiDialog
      fullScreen={props.fullScreen}
      fullWidth
      maxWidth={props.maxWidth || "md"}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.handleClose}>
          {props.closeBtn || "Annuleer"}
        </Button>
        {!props.onlyClose && (
          <LoadingButton
            disabled={props.btnDisabled}
            variant="text"
            loading={props.loading}
            noFullWidth
            label={props.confirmBtn || "Bevestig"}
            onClick={props.handleSubmit}
          />
        )}
      </DialogActions>
    </MuiDialog>
  )
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  closeBtn: PropTypes.string,
  confirmBtn: PropTypes.string,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  btnDisabled: PropTypes.bool,
  onlyClose: PropTypes.bool,
}

export default Dialog
