import React from "react"
import { TextField } from "@material-ui/core"
import PropTypes from "prop-types"
import styles from "../themesAndStyles/styles"

const Input = ({ noFullWidth, label, error, value, onChange, ...rest }) => {
  return (
    <TextField
      error={Boolean(error)}
      helperText={error}
      value={value}
      margin="dense"
      label={label}
      variant={styles.inputVariant}
      fullWidth={!noFullWidth}
      onChange={onChange}
      {...rest}
    />
  )
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Input
