import { useContext } from "react"
import { AppContext } from "../context/AppContext"

const useUnsubscribes = () => {
  const { state } = useContext(AppContext)
  const { unsubscribes } = state

  return unsubscribes
}

export default useUnsubscribes
