import React, { Fragment } from "react"
import { NavLink } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import useExpiredTestLessons from "../hooks/useExpiredTestLessons"
import useTodos from "../hooks/useTodos"
import { Badge } from "@material-ui/core"
import { differenceInCalendarDays } from "date-fns"
import { parseISO, isPast } from "date-fns/"
import useGroupLessons from "../hooks/useGroupLessons"

const useStyles = makeStyles((theme) => ({
  navLink: {
    borderRadius: 4,
    color: "white",
    textDecoration: "none",
    fontWeight: "bolder",
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    boxSizing: "border-box",
  },
  active: {
    border: "solid 1px white",
  },
}))

const ManagerDesktop = () => {
  const classes = useStyles()
  const expiredTestLessons = useExpiredTestLessons()
  const { tasks } = useTodos()
  const groupLessons = useGroupLessons()

  const emptyGroups = groupLessons.filter(
    (lesson) =>
      lesson.attendees.length <= 1 &&
      !lesson.isOption &&
      lesson.typeOfLesson !== "testlesson" &&
      isPast(parseISO(lesson.dateLesson))
  )

  const expiredTasks = tasks.filter(
    (task) =>
      task.deadLine &&
      differenceInCalendarDays(parseISO(task.deadLine), new Date()) <= 0
  )

  return (
    <Fragment>
      <NavLink
        exact
        activeClassName={classes.active}
        className={classes.navLink}
        to="/"
      >
        Rooster
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/students"
      >
        Leerlingen
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/lessoncards"
      >
        Strippenkaarten
      </NavLink>
      <NavLink
        style={{
          color: expiredTestLessons.length ? "#ffcc80" : "",
          border: expiredTestLessons.length ? "solid 1px #ffcc80" : "",
        }}
        activeClassName={classes.active}
        className={classes.navLink}
        to="/testlessons"
      >
        Proeflessen
        <Badge
          style={{ top: -20, right: -8 }}
          color="error"
          badgeContent={expiredTestLessons.length}
        />
      </NavLink>
      <NavLink
        style={{
          color: emptyGroups.length ? "#ffcc80" : "",
          border: emptyGroups.length ? "solid 1px #ffcc80" : "",
        }}
        activeClassName={classes.active}
        className={classes.navLink}
        to="/grouplessons"
      >
        Groepslessen
        <Badge
          style={{ top: -20, right: -8 }}
          color="error"
          badgeContent={emptyGroups.length}
        />
      </NavLink>
      <NavLink
        style={{
          color: expiredTasks.length ? "#ffcc80" : "",
          border: expiredTasks.length ? "solid 1px #ffcc80" : "",
        }}
        activeClassName={classes.active}
        className={classes.navLink}
        to="/todo"
      >
        ToDo
        <Badge
          style={{ top: -20, right: -8 }}
          color="error"
          badgeContent={expiredTasks.length}
        />
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/waitinglists"
      >
        Wachtlijst
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/email"
      >
        E-mail
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/hours"
      >
        Urenlijsten
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/yearschedules"
      >
        Jaarroosters
      </NavLink>
    </Fragment>
  )
}

export default ManagerDesktop
