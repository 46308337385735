import React, { useReducer } from "react"
import lessonReducers from "./reducerFunctions/lessonReducers"

const reducer = (state, action) => {
  switch (action.type) {
    case "setlessons":
      return lessonReducers.setLessons(state, action)
    case "addlessontocard":
      return lessonReducers.addLessonToCard(state, action)
    case "deletelessonfromcard":
      return lessonReducers.deleteLessonFromCard(state, action)
    case "updatelesson":
      return lessonReducers.updateLesson(state, action)
    default:
      return { ...state }
  }
}

const initialState = {
  regularLessons: [],
  lessonCards: [],
  testLessons: []
}

const LessonContext = React.createContext(initialState)

const LessonContextProvider = props => {
  const [lessonState, lessonDispatch] = useReducer(reducer, initialState)
  return (
    <LessonContext.Provider value={{ lessonState, lessonDispatch }}>
      {props.children}
    </LessonContext.Provider>
  )
}

export { LessonContext, LessonContextProvider }
