import http from "./http"
import { notify } from "../common/Notification"

const apiEndpoint = `${process.env.REACT_APP_API}/auth`

async function login(email, password) {
  await http.post(apiEndpoint + "/login", { email, password })
}

async function logout() {
  try {
    await http.get(apiEndpoint + "/logout")
    window.location = "/"
  } catch (error) {
    console.log(error)
    notify("Er ging iets mis", "error")
  }
}

// Check if this works
async function getLoggedInUser() {
  try {
    const { data: user } = await http.get(`${apiEndpoint}/loggedinuser`)
    return user
  } catch (error) {
    console.log(error)
  }
}

const auth = {
  login,
  logout,
  getLoggedInUser,
}

export default auth
