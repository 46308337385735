const unsubscribeReducers = {
  setUnsubscribes: (state, action) => {
    return {
      ...state,
      unsubscribes: action.payload,
    }
  },
  deleteUnsubscribe: (state, action) => {
    const unsubscribes = state.unsubscribes.filter(
      ({ _id }) => !(action.payload === _id)
    )
    return { ...state, unsubscribes }
  },
}

export default unsubscribeReducers
