import { useEffect, useState } from "react"
import auth from "../services/auth"
import useAppContext from "./useAppContext"

const useCheckAuth = () => {
  const [loading, setLoading] = useState(true)
  const { dispatch } = useAppContext()

  useEffect(() => {
    const fecthUser = async () => {
      setLoading(true)
      const user = await auth.getLoggedInUser()
      setLoading(false)
      if (Object.keys(user).length) {
        dispatch({ type: "setuser", payload: user })
      }
    }
    fecthUser()
  }, [dispatch])
  return loading ? "loading" : null
}

export default useCheckAuth
