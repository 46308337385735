import Joi from "joi-browser"
import validate from "./validate"

const schema = {
  password: Joi.string()
    .min(8)
    .max(1024)
    .required()
    .error(() => {
      return {
        message: "Het wachtwoord moet minimaal 8 tekens lang zijn. ",
      }
    }),
}

const validateResetPassword = (input) => validate(input, schema)

export default validateResetPassword
