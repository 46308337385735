import { useEffect, useContext } from "react"
import { SchoolContext } from "../context/SchoolContext"
import { TeacherContext } from "../context/TeacherContext"
import { LessonContext } from "../context/LessonContext"
import { AppContext } from "../context/AppContext"
import http from "../services/http"
import { StudentContext } from "../context/StudentContext"
import { ScheduleContext } from "../context/YearScheduleContext"

const useFetchData = () => {
  const { dispatch } = useContext(AppContext)
  const { schoolDispatch } = useContext(SchoolContext)
  const { teacherDispatch } = useContext(TeacherContext)
  const { studentDispatch } = useContext(StudentContext)
  const { lessonDispatch } = useContext(LessonContext)
  const { scheduleDispatch } = useContext(ScheduleContext)

  useEffect(() => {
    const fetchSchools = async () => {
      dispatch({ type: "setloading", payload: "schools" })
      const { data } = await http.get(`${process.env.REACT_APP_API}/schools`)
      schoolDispatch({ type: "setschools", payload: data })
      dispatch({
        type: "filterschool",
        payload: data[0] || { _id: "", teachers: [] },
      })
      dispatch({ type: "setloading", payload: "schools" })
    }
    fetchSchools()
  }, [schoolDispatch, dispatch])

  useEffect(() => {
    const fetchTeachers = async () => {
      dispatch({ type: "setloading", payload: "teachers" })
      const { data } = await http.get(`${process.env.REACT_APP_API}/teachers`)
      teacherDispatch({ type: "setteachers", payload: data })
      dispatch({ type: "setloading", payload: "teachers" })
    }
    fetchTeachers()
  }, [teacherDispatch, dispatch])

  useEffect(() => {
    const fetchStudents = async () => {
      dispatch({ type: "setloading", payload: "students" })
      const { data } = await http.get(`${process.env.REACT_APP_API}/students`)
      studentDispatch({ type: "setstudents", payload: data })
      dispatch({ type: "setloading", payload: "students" })
    }
    fetchStudents()
  }, [studentDispatch, dispatch])

  useEffect(() => {
    const fetchLessons = async () => {
      dispatch({ type: "setloading", payload: "lessons" })
      const { data } = await http.get(`${process.env.REACT_APP_API}/lessons`)
      lessonDispatch({ type: "setlessons", payload: data })
      dispatch({ type: "setloading", payload: "lessons" })
    }
    fetchLessons()
  }, [lessonDispatch, dispatch])

  useEffect(() => {
    const fetchYearSchedules = async () => {
      dispatch({ type: "setloading", payload: "yearSchedules" })
      const { data } = await http.get(
        `${process.env.REACT_APP_API}/yearschedule`
      )
      scheduleDispatch({ type: "setyearschedules", payload: data })
      dispatch({ type: "setloading", payload: "yearSchedules" })
    }
    fetchYearSchedules()
  }, [scheduleDispatch, dispatch])

  useEffect(() => {
    const fetchTodos = async () => {
      dispatch({ type: "setloading", payload: "todos" })
      const { data } = await http.get(`${process.env.REACT_APP_API}/todo`)
      dispatch({ type: "settodos", payload: data })
      dispatch({ type: "setloading", payload: "todos" })
    }
    fetchTodos()
  }, [dispatch])

  useEffect(() => {
    const fetchWaitingLists = async () => {
      dispatch({ type: "setloading", payload: "waitingLists" })
      const { data } = await http.get(
        `${process.env.REACT_APP_API}/waitinglist`
      )
      dispatch({ type: "setwaitinglists", payload: data })
      if (data.length)
        dispatch({ type: "filterwaitinglist", payload: data[0]._id })

      dispatch({ type: "setloading", payload: "waitingLists" })
    }
    fetchWaitingLists()
  }, [dispatch])

  useEffect(() => {
    const fetchUnsubscribes = async () => {
      const { data } = await http.get(
        `${process.env.REACT_APP_API}/unsubscribes`
      )
      dispatch({ type: "setunsubscribes", payload: data })
    }
    fetchUnsubscribes()
  }, [dispatch])
}

export default useFetchData
