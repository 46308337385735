import React, { useReducer } from "react"

const reducer = (state, action) => {
  switch (action.type) {
    case "setyearschedules":
      return { ...state, yearSchedules: action.payload }
    default:
      return { ...state }
  }
}

const initialState = {
  yearSchedules: { zuid: [], midden: [], noord: [] },
}

const ScheduleContext = React.createContext(initialState)

const ScheduleContextProvider = (props) => {
  const [scheduleState, scheduleDispatch] = useReducer(reducer, initialState)
  return (
    <ScheduleContext.Provider value={{ scheduleState, scheduleDispatch }}>
      {props.children}
    </ScheduleContext.Provider>
  )
}

export { ScheduleContext, ScheduleContextProvider }
