import React, { useReducer } from "react"

const reducer = (state, action) => {
  switch (action.type) {
    case "setschools":
      return { ...state, schools: action.payload }
    case "addschool":
      const schools = [...state.schools]
      schools.push(action.payload)
      return { ...state, schools }
    default:
      return { ...state }
  }
}

const initialState = {
  schools: [{ _id: "", locations: [], teachers: [{ _id: "" }] }]
}

const SchoolContext = React.createContext(initialState)

const SchoolContextProvider = props => {
  const [schoolState, schoolDispatch] = useReducer(reducer, initialState)
  return (
    <SchoolContext.Provider value={{ schoolState, schoolDispatch }}>
      {props.children}
    </SchoolContext.Provider>
  )
}

export { SchoolContext, SchoolContextProvider }
