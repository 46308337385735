import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { NavLink } from "react-router-dom"

const useStyles = makeStyles(theme => ({
  navLink: {
    borderRadius: 4,
    color: "white",
    textDecoration: "none",
    fontWeight: "bolder",
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    boxSizing: "border-box"
  },
  active: {
    border: "solid 1px white"
  }
}))

const TeacherDesktop = () => {
  const classes = useStyles()
  return (
    <Fragment>
      <NavLink
        exact
        activeClassName={classes.active}
        className={classes.navLink}
        to="/"
      >
        Rooster
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/students"
      >
        Leerlingen
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/lessoncards"
      >
        Strippenkaarten
      </NavLink>

      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/email"
      >
        E-mail
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/hours"
      >
        Urenlijsten
      </NavLink>
      <NavLink
        activeClassName={classes.active}
        className={classes.navLink}
        to="/yearschedules"
      >
        Jaarroosters
      </NavLink>
    </Fragment>
  )
}

export default TeacherDesktop
