import _ from "lodash"
import { EditorState } from "draft-js"

const mailReducers = {
  undoMailDelete: (state, action) => {
    const newDeleted = state.mailState.deleted.filter(
      (adress) => !_.isEqual(adress, action.payload)
    )
    const newSelected = [...state.mailState.selected, action.payload]
    return {
      ...state,
      mailState: {
        ...state.mailState,
        selected: newSelected,
        deleted: newDeleted,
      },
    }
  },
  clearMailStates: (state) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        mailSchool: "",
        mailTeacher: "",
        mailDay: "",
        mailDate: null,
        mailAllStudents: false,
      },
      mailState: {
        ...state.mailState,
        selected: [],
        deleted: [],
        added: [],
      },
    }
  },
  clearEditor: (state) => {
    return {
      ...state,
      mailState: {
        ...state.mailState,
        editorState: EditorState.createEmpty(),
        subject: "",
      },
    }
  },
  addMailAddresses: (state, action) => {
    return {
      ...state,
      mailState: {
        ...state.mailState,
        added: [...state.mailState.added, ...action.payload],
      },
    }
  },
  setEditorState: (state, action) => {
    return {
      ...state,
      mailState: {
        ...state.mailState,
        editorState: action.payload,
      },
    }
  },
  setSubject: (state, action) => {
    return {
      ...state,
      mailState: {
        ...state.mailState,
        subject: action.payload,
      },
    }
  },
  setMailAdresses: (state, action) => {
    return {
      ...state,
      mailState: {
        ...state.mailState,
        selected: action.payload,
      },
    }
  },
  deleteMailAdress: (state, action) => {
    const newDeleted = [...state.mailState.deleted, action.payload]
    const newSelected = state.mailState.selected.filter(
      (adress) => !_.isEqual(adress, action.payload)
    )
    return {
      ...state,
      mailState: {
        ...state.mailState,
        selected: newSelected,
        deleted: newDeleted,
      },
    }
  },
}

export default mailReducers
