import React, { Fragment, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { NavLink } from "react-router-dom"
import { IconButton, Drawer, Divider, Button, Hidden } from "@material-ui/core"
import { Menu } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  drawer: {
    backgroundColor: theme.palette.primary.main
  },
  navButton: {
    color: "white"
  },
  linkContainer: {
    padding: theme.spacing(1)
  },
  navlink: {
    color: "white",
    textDecoration: "none"
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 56,
    "@media (orientation: landscape)": { marginTop: 48 }
  },
  hamburger: {
    color: "white"
  }
}))

const navLinks = [
  { title: "Rooster", to: "/", exact: true },
  { title: "Leerlingen", to: "/students" },
  { title: "Strippenkaarten", to: "/lessoncards" },
  { title: "Email", to: "/email", hidden: { xsDown: true } },
  { title: "Urenlijsten", to: "/hours", hidden: { xsDown: true } },
  { title: "Jaaroosters", to: "/yearschedules" }
]

const TeacherMobile = () => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      <IconButton className="navBarButton" onClick={() => setOpen(!open)}>
        <Menu className={classes.hamburger} />
      </IconButton>
      <Drawer
        classes={{ paper: classes.drawer }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Divider className={classes.divider} />
        {navLinks.map(link => {
          return (
            <Hidden key={link.title} {...link.hidden}>
              <NavLink
                exact={link.exact}
                to={link.to}
                className={classes.navlink}
                onClick={handleClose}
              >
                <Button variant="text" className={classes.navButton}>
                  {link.title}
                </Button>
              </NavLink>
            </Hidden>
          )
        })}
      </Drawer>
    </Fragment>
  )
}

export default TeacherMobile
