import React, { useState } from "react"
import {
  AppBar,
  Toolbar,
  Badge,
  Tooltip,
  Menu,
  MenuItem,
  Hidden
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import { SettingsOutlined } from "@material-ui/icons"
import auth from "../services/auth"
import FullPageSpinner from "../common/FullPageSpinner"
import TeacherDesktop from "./TeacherDesktop"
import TeacherMobile from "./TeacherMobile"

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  links: { flexGrow: 1 },
  navLink: {
    borderRadius: 4,
    color: "white",
    textDecoration: "none",
    fontWeight: "bolder",
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    boxSizing: "border-box"
  },
  menuLink: {
    color: theme.palette.secondary.main,
    textDecoration: "none",
    textAlign: "right"
  },
  active: {
    border: "solid 1px white"
  }
}))

const TeachersNavBar = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [loggingOut, setLoggingOut] = useState(false)

  const handleSettingsClose = () => setAnchorEl(null)

  return (
    <div className={classes.root}>
      {loggingOut && <FullPageSpinner label="Uitloggen..." />}

      <AppBar position="sticky">
        <Toolbar>
          <div className={classes.links}>
            <Hidden mdDown>
              <TeacherDesktop />
            </Hidden>
            <Hidden lgUp>
              <TeacherMobile />
            </Hidden>
          </div>
          <div>
            <Tooltip title="Settings" placement="left">
              <Badge>
                <SettingsOutlined
                  aria-controls="settingsMenu"
                  aria-haspopup="true"
                  onClick={e => setAnchorEl(e.currentTarget)}
                />
              </Badge>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        id="settingsMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleSettingsClose}
      >
        <NavLink className={classes.menuLink} to="/accountsettings">
          <MenuItem onClick={handleSettingsClose}>Mijn Account</MenuItem>
        </NavLink>
        <MenuItem
          className={classes.menuLink}
          onClick={() => {
            setLoggingOut(true)
            auth.logout()
          }}
        >
          Uitloggen
        </MenuItem>
      </Menu>
    </div>
  )
}

export default TeachersNavBar
