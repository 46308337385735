import React, { lazy, Suspense } from "react"
import "./App.css"
import AddButton from "./components/AddButton"
import AddItems from "./components/AddItems"
import { Switch, Route } from "react-router-dom"
import { Container } from "@material-ui/core"
import useFetchData from "./hooks/useFetchData"
import ManagerNavBar from "./navigation/ManagerNavBar"
import UnsubscribeWarning from "./components/UnsubscribeWarning"
import FullPageSpinner from "./common/FullPageSpinner"
import ScrollToTop from "./ScrollToTop"

const Login = lazy(() => import("./components/Login"))
const AddSchoolForm = lazy(() => import("./formcomponents/AddSchoolForm"))
const AddTeacher = lazy(() => import("./components/AddTeacher"))
const AddStudent = lazy(() => import("./components/AddStudent"))
const Students = lazy(() => import("./components/Students"))
const Student = lazy(() => import("./components/Student"))
const LessonCards = lazy(() => import("./components/LessonCards"))
const LessonCard = lazy(() => import("./components/LessonCard"))
const TestLessons = lazy(() => import("./components/TestLessons"))
const GroupLessons = lazy(() => import("./components/GroupLessons"))
const GroupLesson = lazy(() => import("./components/GroupLesson"))
const AddYearSchedule = lazy(() => import("./components/AddYearSchedule"))
const YearSchedules = lazy(() => import("./components/YearSchedules"))
const Todo = lazy(() => import("./components/Todo"))
const WaitingLists = lazy(() => import("./components/WaitingLists"))
const Mail = lazy(() => import("./components/Mail"))
const Hours = lazy(() => import("./components/Hours"))
const UserManagement = lazy(() => import("./components/UserManagement"))
const NoInvoice = lazy(() => import("./components/NoInvoice"))
const TeachersAndSchools = lazy(() => import("./components/TeachersAndSchools"))
const Unsubscribes = lazy(() => import("./components/Unsubscribes"))
const Unsubscribe = lazy(() => import("./components/Unsubscribe"))
const AccountSettings = lazy(() => import("./components/AccountSettings"))
const Schedule = lazy(() => import("./components/Schedule"))
const MakeContract = lazy(() => import("./components/MakeContract"))
const ChangeTeacher = lazy(() => import("./components/ChangeTeacher"))
const Prices = lazy(() => import("./components/prices/Prices"))
const NoBtwStudents = lazy(() => import("./components/NoBtwStudents"))
const NoMoneyBirdStudents = lazy(() =>
  import("./components/NoMoneyBirdStudents")
)

function ManagersApp() {
  useFetchData()

  return (
    <div>
      <ScrollToTop />
      <AddButton actions={AddItems} />
      <UnsubscribeWarning />
      <ManagerNavBar />
      <Container>
        <Suspense fallback={<FullPageSpinner />}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/addschool" component={AddSchoolForm} />
            <Route path="/addteacher" component={AddTeacher} />
            <Route path="/addstudent" component={AddStudent} />
            <Route exact path="/students" component={Students} />
            <Route path="/students/:id" component={Student} />
            <Route exact path="/lessoncards" component={LessonCards} />
            <Route path="/lessoncards/:id" component={LessonCard} />
            <Route path="/testlessons" component={TestLessons} />
            <Route exact path="/grouplessons" component={GroupLessons} />
            <Route path="/grouplessons/:id" component={GroupLesson} />
            <Route path="/addyearschedule" component={AddYearSchedule} />
            <Route path="/yearschedules" component={YearSchedules} />
            <Route path="/todo" component={Todo} />
            <Route path="/waitinglists" component={WaitingLists} />
            <Route path="/email" component={Mail} />
            <Route path="/hours" component={Hours} />
            <Route path="/manageusers" component={UserManagement} />
            <Route path="/noinvoice" component={NoInvoice} />
            <Route path="/teachersandschools" component={TeachersAndSchools} />
            <Route exact path="/unsubscribes" component={Unsubscribes} />
            <Route path="/unsubscribes/:id" component={Unsubscribe} />
            <Route path="/accountsettings" component={AccountSettings} />
            <Route path="/makecontract" component={MakeContract} />
            <Route path="/changeteacher" component={ChangeTeacher} />
            <Route path="/no-moneybird" component={NoMoneyBirdStudents} />
            <Route path="/no-btw" component={NoBtwStudents} />
            <Route path="/prices" component={Prices} />
            <Route exact path="/" component={Schedule} />
          </Switch>
        </Suspense>
      </Container>
    </div>
  )
}

export default ManagersApp
