const taskReducers = {
  setTodos: (state, action) => {
    return {
      ...state,
      todo: {
        tasks: action.payload.tasks,
        categorys: action.payload.categorys,
      },
    }
  },
  setWaitingLists: (state, action) => {
    return {
      ...state,
      waitingLists: action.payload,
    }
  },
}

export default taskReducers
