import React, { useReducer } from "react"
import { SchoolContextProvider } from "./SchoolContext"
import { TeacherContextProvider } from "./TeacherContext"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { StudentContextProvider } from "./StudentContext"
import { LessonContextProvider } from "./LessonContext"
import { ScheduleContextProvider } from "./YearScheduleContext"
import { createTheme, MuiThemeProvider } from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns"
import { nl } from "date-fns/esm/locale"
import mainTheme from "../themesAndStyles/mainTheme"
import mailReducers from "./reducerFunctions/mailReducers"
import { EditorState } from "draft-js"
import filterReducers from "./reducerFunctions/filterReducers"
import taskReducers from "./reducerFunctions/taskReducers"
import loaderReducers from "./reducerFunctions/loaderReducers"
import unsubscribeReducers from "./reducerFunctions/unsubscribeReducers"

const reducer = (state, action) => {
  switch (action.type) {
    case "filterschool":
      return filterReducers.filterSchool(state, action)
    case "filterteacher":
      return filterReducers.filterTeacher(state, action)
    case "filterday":
      return filterReducers.filterDay(state, action)
    case "filtertodocategory":
      return filterReducers.filterTodoCategory(state, action)
    case "filterwaitinglist":
      return filterReducers.filterWaitingLists(state, action)
    case "filtermailschool":
      return filterReducers.filterMailSchool(state, action)
    case "filtermailallstudents":
      return filterReducers.filterMailAllStudents(state, action)
    case "filterhoursteacher":
      return filterReducers.filterHoursTeacher(state, action)
    case "filtermailteacher":
      return filterReducers.filterMailTeacher(state, action)
    case "filtermailday":
      return filterReducers.filterMailDay(state, action)
    case "filtermaildate":
      return filterReducers.filterMailDate(state, action)
    case "filterstudentsteacher":
      return filterReducers.filterStudentsTeacher(state, action)
    case "filternoinvoiceschool":
      return filterReducers.filterNoInvoiceSchool(state, action)
    case "undomaildelete":
      return mailReducers.undoMailDelete(state, action)
    case "addmailaddresses":
      return mailReducers.addMailAddresses(state, action)
    case "seteditorstate":
      return mailReducers.setEditorState(state, action)
    case "setsubject":
      return mailReducers.setSubject(state, action)
    case "setmailadresses":
      return mailReducers.setMailAdresses(state, action)
    case "clearmailstates":
      return mailReducers.clearMailStates(state)
    case "cleareditor":
      return mailReducers.clearEditor(state)
    case "deletemailadress":
      return mailReducers.deleteMailAdress(state, action)
    case "settodos":
      return taskReducers.setTodos(state, action)
    case "setwaitinglists":
      return taskReducers.setWaitingLists(state, action)
    case "setloading":
      return loaderReducers.setLoader(state, action)
    case "setunsubscribes":
      return unsubscribeReducers.setUnsubscribes(state, action)
    case "deleteunsubscribe":
      return unsubscribeReducers.deleteUnsubscribe(state, action)
    case "setuser":
      return { ...state, user: action.payload }
    default:
      return { ...state }
  }
}

const initialState = {
  filters: {
    school: "",
    teacher: "",
    date: new Date(),
    todoCategory: "",
    waitingList: "",
    mailSchool: "",
    mailTeacher: "",
    mailDay: "",
    mailAllStudents: false,
    mailDate: null,
    hoursTeacher: "",
    studentsTeacher: "",
    noInvoiceSchool: "",
  },
  mailState: {
    subject: "",
    editorState: EditorState.createEmpty(),
    selected: [],
    deleted: [],
    added: [],
  },
  todo: {
    tasks: [],
    categorys: [],
  },
  waitingLists: [],
  user: {},
  loaders: {
    schedule: false,
    students: false,
    schools: false,
    teachers: false,
    lessons: false,
    yearSchedules: false,
    todos: false,
    waitingLists: false,
  },
  unsubscribes: [],
}

const AppContext = React.createContext(initialState)
const theme = createTheme(mainTheme)

const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ScheduleContextProvider>
      <LessonContextProvider>
        <StudentContextProvider>
          <TeacherContextProvider>
            <SchoolContextProvider>
              <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
                  <AppContext.Provider value={{ state, dispatch }}>
                    {props.children}
                  </AppContext.Provider>
                </MuiPickersUtilsProvider>
              </MuiThemeProvider>
            </SchoolContextProvider>
          </TeacherContextProvider>
        </StudentContextProvider>
      </LessonContextProvider>
    </ScheduleContextProvider>
  )
}

export { AppContext, AppContextProvider }
