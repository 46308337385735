import React from "react"
import { Button, CircularProgress, makeStyles } from "@material-ui/core"
import styles from "../themesAndStyles/styles"
import PropTypes from "prop-types"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  spinner: { marginRight: theme.spacing(2) },
  loadingText: { color: theme.palette.secondary.main },
  topMargin: { marginTop: theme.spacing(1) },
}))

const LoadingButton = (props) => {
  const classes = useStyles()
  const topMargin = props.withTopMargin ? classes.topMargin : null

  return (
    <Button
      className={clsx(props.className, topMargin)}
      size={props.size}
      disabled={props.disabled || props.loading}
      variant={props.variant || styles.mainBtn}
      color={styles.mainBtnColor}
      onClick={props.onClick}
      fullWidth={!props.noFullWidth}
      type={props.type}
    >
      {props.loading && (
        <CircularProgress
          className={classes.spinner}
          color={styles.mainBtnColor}
          size={20}
        />
      )}
      {props.loading ? (
        <span className={classes.loadingText}>laden...</span>
      ) : (
        props.label
      )}
    </Button>
  )
}

LoadingButton.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default LoadingButton
