import React, { useState } from "react"
import { Fab, makeStyles, ClickAwayListener, Collapse } from "@material-ui/core"
import { Add } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    zIndex: 99
  }
}))

const AddButton = ({ actions: Actions }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <Collapse in={open} timeout={100}>
            <Actions />
          </Collapse>
          <Fab color="secondary" onClick={() => setOpen(!open)}>
            <Add />
          </Fab>
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default AddButton
